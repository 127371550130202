import Dexie, { type EntityTable } from "dexie"
import { type INotifyProps } from "./components/NotificationItem.vue"

interface IWallet {
	id: number
	name: string
	address: string
	private_key: string
	seed: string
	networkApi: string
}

interface INetwork {
	id: number
	name: string
	usdtContractAddress: string
	api: string
	defaultNet: boolean
}

interface ITransaction {
	id?: string
	token: "TRX" | "USDT"
	amount: number
	amountUsd: number
	date?: number
	status: string
	receiver: string
	fee: number
	feeUsd: number
	testNet?: boolean
	type: "transfer" | "swap"
  wallet?: string
}

// Wallets
const db = new Dexie("WalletDatabase") as Dexie & {
	wallets: EntityTable<IWallet, "id">
}
db.version(1).stores({
	wallets: "++id, name, address ,private_key, seed, networkApi",
})

// Active wallet
const activeWalletDb = new Dexie("ActiveWalletDb") as Dexie & {
	wallet: EntityTable<IWallet, "id">
}
activeWalletDb.version(1).stores({
	wallet: "++id, name, address ,private_key, seed, networkApi",
})

// Networks
const newtworksDb = new Dexie("NetworkDb") as Dexie & {
	networks: EntityTable<INetwork, "id">
	active: EntityTable<INetwork, "id">
}
newtworksDb.version(1).stores({
	networks: "++id, name, usdtContractAddress, api, defaultNet",
	active: "++id, name, usdtContractAddress, api, defaultNet",
})

// Notification
const notificationsDb = new Dexie("NotificationsDb") as Dexie & {
	notifications: EntityTable<INotifyProps & { id: string }, "id">
	last: EntityTable<{ id: string; date: string }, "id">
}
notificationsDb.version(1).stores({
	notifications: "++id, type, title, date, text",
	last: "++id, date",
})

// Transactions
const transactionsDb = new Dexie("TransactionsDb") as Dexie & {
	transactions: EntityTable<ITransaction & { id: string }, "id">
	recents: EntityTable<ITransaction, "id">
}
transactionsDb.version(1).stores({
	transactions: "++id, token, amount, amountUsd, date, status, receiver, fee, feeUsd, network, type, wallet",
	recents: "++id, token, amount, amountUsd, date, status, receiver, fee, feeUsd, network, type, wallet",
})

export type { IWallet, INetwork, ITransaction }
export { db, activeWalletDb, newtworksDb, notificationsDb as notificationDb, transactionsDb }
