<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useDark } from '@vueuse/core';
// import WebApp from '@twa-dev/sdk';
import Utils from './utils';
import { getCurrentWallet, getWallets } from './jsBackend/wallet';
import { initTron } from './jsBackend/tronweb';
import { ls } from './utils/localStorage';
import { usePrimeVue } from 'primevue/config';
import { initNetworkDb } from './jsBackend/network';
const PrimeVue = usePrimeVue();

const router = useRouter();
window.Telegram.WebApp.BackButton.onClick(() => {
  router.go(-1)
})
onMounted(async () => {
  const isDark = useDark()
  if (isDark.value) PrimeVue.changeTheme('aura-light-blue', 'aura-dark-blue', 'theme-link', () => { })
  // @ts-ignore
  const { activeNetwork } = await initNetworkDb()
  const currentWallet = await getCurrentWallet()
  if (currentWallet && activeNetwork?.api) {
    initTron(currentWallet.private_key, activeNetwork.api)
  }
  // Preload Audio files that we are going to use
  Utils.PreLoadAudio('Success.mp3');
  Utils.PreLoadAudio('Failed.mp3');
  const wallets = await getWallets();
  const lockTime = ls.get('lock-time') || null
  if (!lockTime || lockTime === 'undefined') ls.set('lock-time', '5')
  const auth = ls.get('auth') || null
  if (!wallets?.length || !auth) router.push('/onboarding');
});

// WebApp.expand();
</script>

<template>
  <router-view class="main-wrapper min-h-screen ">
  </router-view>
</template>

<style lang="scss">
.main-wrapper {
  min-height: 100vh;
}

@supports (height: 100dvh) {
  .main-wrapper {
    min-height: 100dvh;
  }
}
</style>