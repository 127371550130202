import type { INetwork } from "@/db"

export const availableNetworks: Omit<INetwork, "id">[] = [
	{
		name: "TRON nile testnet",
		api: "https://nile.trongrid.io",
		usdtContractAddress: "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj",
		defaultNet: false,
	},
	{
		name: "TRON mainnet (TronGrid)",
		api: "https://api.trongrid.io",
		usdtContractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
		defaultNet: true,
	},
]
