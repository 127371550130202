type LocalStorageKeys = 'password' | 'auth' | 'address' | 'send-token' | 'trx-price' | 'lock-time' | 'new-wallet' | 'conversion' | 'primary-currency' | 'lang' | 'last-notification' | 'ustd-only'

export const ls = {
  get: (key: LocalStorageKeys) => {
    return localStorage.getItem(key)
  },
  set: (key: LocalStorageKeys, value: string) => {
    localStorage.setItem(key, value)
  },
  remove: (key: LocalStorageKeys) => {
    localStorage.removeItem(key)
  }
}