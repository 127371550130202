import { defineStore } from "pinia"
import { computed, ref } from 'vue'
import type TronWeb from 'tronweb'

export const useTronStore = defineStore("tron", () => {
  const tronInstance = ref<TronWeb>()

  const getTronInstance = computed(() => tronInstance.value)

  function setTronInstance(tron: TronWeb) {
    tronInstance.value = tron
  }
	return { getTronInstance, setTronInstance }
})
