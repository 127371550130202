import { onClickOutside, type Fn } from "@vueuse/core"
import type { ObjectDirective } from "vue"

interface HTMLElementWithStopWatch extends HTMLElement {
	_stopWatch?: Fn
}

const outclickDirective: ObjectDirective<HTMLElementWithStopWatch> = {
	mounted(el) {
		el.tabIndex = 0

		const handleClickOutside = () => {
			el.tabIndex = -1
			if (el.children[0] && el.children[0] instanceof HTMLInputElement) {
				el.children[0].tabIndex = -1
			}
		}

		el._stopWatch = onClickOutside(el, handleClickOutside)
	},

	unmounted(el) {
		if (el._stopWatch) {
			el._stopWatch()
		}
	},
}

export default outclickDirective
