import { newtworksDb, type INetwork } from "@/db"
import { availableNetworks } from "@/config/networks"

export async function initNetworkDb() {
	try {
		await newtworksDb.networks.clear()
		// availableNetworks.forEach(async (net) => {
		// 	await newtworksDb.networks.add(net)
		// })
    await newtworksDb.networks.bulkAdd(availableNetworks)
		const activeNetwork = await getActiveNetwork()
    if (!activeNetwork) await setActiveNetwork()
		return { activeNetwork }
	} catch (error) {
		console.log("❌ Ошибка инициализации БД (networks)", error)
	}
}

export async function getNetworks() {
	try {
		return await newtworksDb.networks.toArray()
	} catch (error) {
		console.log("⚠️ БД с networks пуста", error)
	}
}

export async function getNetwork(api: string) {
	try {
		const data = await newtworksDb.networks.where({ api }).first()
		return data
	} catch (error) {
		console.log(`❌ Ошибка получения БД (network ${api})`, error)
	}
}

export async function getActiveNetwork() {
	try {
		const activeNetwork = (await newtworksDb.active.orderBy("id").first()) || null
		if (activeNetwork) {
			const match = await getNetwork(activeNetwork.api)
			if (match) {
				return activeNetwork
			} else {
				await newtworksDb.active.clear()
			}
		}
    return null
		// const defaultNetwork = (await newtworksDb.networks.toArray()).find((item) => item.defaultNet)
		// if (!defaultNetwork) throw new Error("БД с networks пуста")
		// await setActiveNetwork(defaultNetwork.api)
		// return defaultNetwork
	} catch (error) {
		console.log(`⚠️ невозможно получить текущую сеть из БД`, error)
	}
}

export async function setActiveNetwork(api?: string) {
	try {
		await newtworksDb.active.clear()
		if (api) {
			const network = availableNetworks.find((item) => item.api == api)
			if (!network) throw new Error("Не найден network из конфига")
			await newtworksDb.active.add(network)
		} else {
			const defaultNetwork = (await newtworksDb.networks.toArray()).find(item => item.defaultNet === true)
			if (!defaultNetwork) throw new Error("БД с networks пуста")
			await newtworksDb.active.add(defaultNetwork)
		}
	} catch (error) {
		console.log(`❌ Ошибка записи БД (active network)`, error)
	}
}
