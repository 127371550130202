import { db, activeWalletDb } from "@/db"
import { getRandomInt } from "@/utils/functions"
import { deriveTronAddressFromMnemonic, initTron } from "./tronweb"
import { getActiveNetwork } from "./network"

const serviceUrl = import.meta.env.VITE_WALLET_SERVICE_URL

export async function createNewWallet() {
	interface IWalletResponse {
		address: string
		privateKey: string
		mnemonic: string
	}



	// try {
	// 	const response = await fetch(serviceUrl + "create_wallet")
	// 	const createdWallet: IWalletResponse = await response.json()
	// 	const activeNetwork = await getActiveNetwork()
	// 	if (!activeNetwork) throw new Error("Нет активного network")
	// 	const wallet = {
	// 		name: "createdWallet-" + getRandomInt(10000),
	// 		address: createdWallet.address,
	// 		private_key: createdWallet.privateKey,
	// 		seed: createdWallet.mnemonic,
	// 		networkApi: activeNetwork.api,
	// 	}
	// 	await db.wallets.add(wallet)
	// 	await setCurrentWallet(wallet.address)
	// } catch (error) {
	// 	console.log("❌ Ошибка запроса на создание кошелька", error)
	// }
	
	
	try {


		const activeNetwork = await getActiveNetwork()

		const response = await fetch(serviceUrl + "create_wallet",
			{
			  method: 'POST',
			  body: JSON.stringify({
				network: activeNetwork.api
			  }),
			  headers: {
				'Content-type': 'application/json; charset=UTF-8',
			  },
			}
		  );



		const createdWallet: IWalletResponse = await response.json()
		if (!activeNetwork) throw new Error("Нет активного network")
		const wallet = {
			name: "createdWallet-" + getRandomInt(10000),
			address: createdWallet.address,
			private_key: createdWallet.privateKey,
			seed: createdWallet.mnemonic,
			networkApi: activeNetwork.api,
		}
		await db.wallets.add(wallet)
		await setCurrentWallet(wallet.address)
	} catch (error) {
		console.log("❌ Ошибка запроса на создание кошелька", error)
	}
}

export async function getWallets() {
	try {
		const activeNetwork = await getActiveNetwork()
		if (!activeNetwork) throw new Error("Нет активного network")
		const data = (await db.wallets.toArray()).filter((wallet) => wallet.networkApi == activeNetwork.api)
		return data
	} catch (error) {
		console.log("❌ Ошибка получения БД (кошельки)", error)
	}
}

export async function getWallet(address: string) {
	try {
		const activeNetwork = await getActiveNetwork()
		if (!activeNetwork) throw new Error("Нет активного network")
		const data = await db.wallets.where({ address }).first()
		return data
	} catch (error) {
		console.log(`❌ Ошибка получения БД (кошелек ${address})`, error)
	}
}
export async function getLastWallet() {
	try {
    const activeNetwork = await getActiveNetwork()
		if (!activeNetwork) throw new Error("Нет активного network")
		const data = await db.wallets.where({networkApi: activeNetwork.api }).last()
		return data
	} catch (error) {
		console.log("❌ Ошибка получения БД (последний кошелек)", error)
	}
}

export async function getCurrentWallet() {
	try {
    const activeNetwork = await getActiveNetwork()
		if (!activeNetwork) throw new Error("Нет активного network")
		const activeWallet = (await activeWalletDb.wallet.orderBy("id").first()) || null
		if (activeWallet) {
			const match = await getWallet(activeWallet.address)
			if (match && activeNetwork.api == activeWallet.networkApi) return activeWallet
		}
		const lastWallet = await getLastWallet()
		if (lastWallet) {
			await setCurrentWallet(lastWallet.address)
			return lastWallet
		}
		return null
	} catch (error) {
		console.log(`⚠️ невозможно получить текущий кошелек из БД`, error)
	}
}

export async function setCurrentWallet(address: string) {
	try {
		const activeWallet = (await activeWalletDb.wallet.orderBy("id").first()) || null
		const foundWallet = await db.wallets.get({ address })
		if (!foundWallet) throw new Error("Wallet not found")
		if (!activeWallet) {
			await activeWalletDb.wallet.add(foundWallet)
			return
		}
		await activeWalletDb.wallet.update(activeWallet.id, foundWallet)

		const activeNetwork = await getActiveNetwork()

		// initTron(foundWallet.private_key, activeWallet.networkApi)
		initTron(foundWallet.private_key, activeNetwork.api)
	} catch (error) {
		console.log(`❌ Wallet ${address} not found`)
	}
}

export async function importWalletDb(seed: string) {
	const createdWallet = await deriveTronAddressFromMnemonic(seed)
  const activeNetwork = await getActiveNetwork()
	if (!activeNetwork) throw new Error("Нет активного network")
	const wallet = {
		name: "importedWallet-" + getRandomInt(10000),
		address: createdWallet.address as string,
		private_key: createdWallet.privateKey,
		seed: seed,
    networkApi: activeNetwork.api
	}

	await db.wallets.add(wallet)
	await setCurrentWallet(wallet.address)
}

export async function editWalletName(address: string, name: string) {
	try {
		await db.wallets
			.where("address")
			.equals(address)
			.modify((wallet) => {
				wallet.name = name
			})
	} catch (error) {
		console.log("❌Ошибка редактирования кошелька", error)
	}
}

export async function deleteWallet(address: string) {
	try {
		await db.wallets.where("address").equals(address).delete()
	} catch (error) {
		console.log("❌Ошибка редактирования кошелька", error)
	}
}

export async function deleteActiveWallet(address: string) {
	try {
		await activeWalletDb.wallet.where("address").equals(address).delete()
	} catch (error) {
		console.log("❌Ошибка удаления активного кошелька", error)
	}
}
