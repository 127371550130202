// @ts-nocheck
import { defineStore } from "pinia"
import { ref } from "vue"
import Utils from "../utils"

// import { useI18n } from "vue-i18n";

// const i18nLocale = useI18n({ useScope: 'global' });
import WebApp from "@twa-dev/sdk"

export const useGlobalStore = defineStore("global", {
	state: () => ({
		timeoutId: null,
		get_wallets_for_user_interval: null,
		selected_wallet: null,
		// assets: {'value':[
		//   {"token": "TRX", "val1":'data.TRXbalance', "val2":"122", "val3":"122", "id":"2323"},
		//   {"token": "USDT", "val1":'data.USDTBalance', "val2":"122", "val3":"122", "id":"2324"}
		// ]},
		assets: { value: [] },
		wallets: { value: [] },
		historyStack: [],
		config: {
			use_backend: true,
		},
	}),
	actions: {
		addRoute(route: string) {
			this.historyStack.push(route)

			// console.log(this.historyStack);
		},

		async get_wallet_info() {
			// console.log('setGlobalVarWithDelay setTimeout');

			const selected_wallet = ref(Utils.GetSelectedWallet())

			//TODO переделать это место на работу через userID
			// console.log('setGlobalVarWithDelay data');
			let data = await Utils.DPXSendRequest(`/get_wallet_info`, { privateKey: selected_wallet._value.privateKey }, "POST")

			data = data.result.wallet_info

			let trx_bal = data.TRXbalance
			let TRXpriceInUSDT = data.TRXpriceInUSDT

			let trx_bal_num = Number(trx_bal)
			let TRXpriceInUSDT_num = Number(TRXpriceInUSDT)

			let trx_balance_in_usdt = trx_bal_num * TRXpriceInUSDT_num

			this.selected_wallet.total_sum = trx_balance_in_usdt.toFixed(3)

			let result = [
				{ token: "TRX", val1: data.TRXbalance, val2: "-", val3: "-", id: "2323" },
				{ token: "USDT", val1: data.USDTBalance, val2: "-", val3: "-", id: "2324" },
			]

			this.assets.value = result
		},

		async get_wallets_for_user() {
			// @ts-ignore
			let data = await Utils.DPXSendRequest(`/get_wallets_for_user`, { user_id: WebApp.initDataUnsafe.user.id }, "POST")

			if (data && `result` in data && data.status === "success") {
				let wallets_data = data

				if (wallets_data && `result` in wallets_data && wallets_data.status === "success") {
					this.wallets.value = wallets_data.result
				}
			} else {
				//TODO поставить здесь всплывашку с ошибкой
				// window.localStorage.removeItem(`wallet_user_${WebApp.initDataUnsafe.user.id}`);
				//window.location.reload();
			}

			// console.log('setGlobalVarWithDelay setTimeout');

			// const selected_wallet = ref(Utils.GetSelectedWallet());

			// // console.log('setGlobalVarWithDelay data');
			// let data = await Utils.DPXSendRequest(`/get_wallet_info`, { 'privateKey': selected_wallet._value.privateKey }, 'POST');

			// data = data.result.wallet_info;

			// let trx_bal = data.TRXbalance;
			// let TRXpriceInUSDT = data.TRXpriceInUSDT;

			// let trx_bal_num = Number(trx_bal);
			// let TRXpriceInUSDT_num = Number(TRXpriceInUSDT);

			// let trx_balance_in_usdt = trx_bal_num * TRXpriceInUSDT_num;

			// this.selected_wallet.total_sum = trx_balance_in_usdt.toFixed(3);

			// let result = [
			//                 {"token": "TRX", "val1":data.TRXbalance, "val2":"-", "val3":"-", "id":"2323"},
			//                 {"token": "USDT", "val1":data.USDTBalance, "val2":"-", "val3":"-", "id":"2324"}
			//             ];

			// this.assets.value = result;
		},

		setGlobalVarWithDelay() {
			console.log("setGlobalVarWithDelay")

			this.get_wallet_info()

			if (this.timeoutId) {
				clearTimeout(this.timeoutId)
			}

			this.timeoutId = setInterval(async () => {
				this.get_wallet_info()
			}, 10000)
		},

		startGetWalletsForUserLoop() {
			console.log("startGetWalletsForUserLoop")

			this.get_wallets_for_user()

			if (this.get_wallets_for_user_interval) {
				clearInterval(this.get_wallets_for_user_interval)
			}

			this.get_wallets_for_user_interval = setInterval(async () => {
				this.get_wallets_for_user()

				// console.log(this.wallets.value);
			}, 10000)
		},

		clearTimeout() {
			if (this.timeoutId) {
				this.timeoutId = null
			}
		},

		clearInterval() {
			if (this.get_wallets_for_user_interval) {
				this.get_wallets_for_user_interval = null
			}
		},
	},
})
