import Toastify from 'toastify-js';
import WebApp from '@twa-dev/sdk';

import TronWeb from 'tronweb';
import bip39 from 'bip39';
import bip32 from 'bip32';
import hdkey from 'hdkey';

// import BIP32Factory from 'bip32';
// import ecc from 'tiny-secp256k1';

// const { BIP32Factory } = require('bip32');
// const ecc = require('tiny-secp256k1'); // A dependency for bip32

import { BIP32Factory } from 'bip32';
// import { ecc } from 'tiny-secp256k1';
import * as secp256k1 from 'tiny-secp256k1';

import { db } from '../db';


let Audios = {};

function getRandomInt(max)
{
    return Math.floor(Math.random() * max);
}

const jsBackend = {
    

    deriveTronAddressFromMnemonic: async (mnemonic) => {

        console.log('deriveTronAddressFromMnemonic=11111');
        // const bip32 = BIP32Factory(ecc);
        const bip32 = BIP32Factory(secp256k1);

        console.log('deriveTronAddressFromMnemonic=22222');

        // Validate mnemonic
        if (!bip39.validateMnemonic(mnemonic)) 
        {
            throw new Error('Invalid mnemonic phrase');
        }
    
        // Generate seed from mnemonic
        const seed = await bip39.mnemonicToSeed(mnemonic);
    
        // Derive BIP32 root key
        const root = bip32.fromSeed(seed);
    
        // Tron uses a specific derivation path
        const tronPath = "m/44'/195'/0'/0/0";
        const child = root.derivePath(tronPath);
    
        // Get the private key in hex format
        const privateKey = child.privateKey.toString('hex');
    
        // Initialize TronWeb instance
        const tronWeb = new TronWeb({
            fullHost: 'https://nile.trongrid.io', // Use the official TronGrid API
        });
    
        // Generate Tron address from the private key
        const address = tronWeb.address.fromPrivateKey(privateKey);
    
        return {
            address: address,
            privateKey: privateKey
        };

    },

    createTronWallet: async () => {

        //Шаг 1: Генерация мнемонической фразы
        const mnemonic = bip39.generateMnemonic();
        // console.log('Mnemonic:', mnemonic);

        // Шаг 2: Преобразование мнемонической фразы в сид
        const seed = bip39.mnemonicToSeedSync(mnemonic);

        // Шаг 3: Генерация мастер-ключа из сида
        const root = hdkey.fromMasterSeed(seed);

        // Шаг 4: Деривация ключей на основе пути (например, m/44'/195'/0'/0/0)
        const addrNode = root.derive("m/44'/195'/0'/0/0");

        // Шаг 5: Получение приватного ключа
        const privateKey = addrNode.privateKey.toString('hex');
        // console.log('Private Key:', privateKey);


        const publicKey = addrNode.publicKey.toString('hex');
        console.log('publicKey:', publicKey);


        // Шаг 6: Инициализация TronWeb с приватным ключом
        const tronWeb = new TronWeb({
            fullHost: 'https://nile.trongrid.io',
            privateKey: privateKey
        });


        // Get the address
        const address = tronWeb.address.fromPrivateKey(privateKey);


        // console.log('Address:', address);
        return {
            privateKey: privateKey,
            mnemonic: mnemonic,
            address: address,
            publicKey: publicKey
        };
    },



    getTrxPriceTronscan: async () => {

        const url = 'https://apilist.tronscanapi.com/api/token/price?token=trx';

        try 
        {
            const response = await fetch(url);

            if (!response.ok) 
            {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            return data['price_in_usd'];

        } 
        catch (error) 
        {
            console.error(`Error: ${error.message}`);
        }

        return null;

    },

    checkBalance: async (privateKey) => {

        try 
        {
        
            // Full node HTTP API endpoint
            const fullNode = 'https://nile.trongrid.io';
    
            const tronWeb = new TronWeb({
              fullHost: fullNode,
              privateKey: privateKey
            });
    
            const balance = await tronWeb.trx.getBalance(tronWeb.defaultAddress.base58);
            // console.log(`Balance: ${tronWeb.fromSun(balance)} TRX`);
    
            const balance_trx = tronWeb.fromSun(balance);
    
            return balance_trx;
        } 
        catch (error) 
        {
            console.error(`Error fetching balance: ${error}`);
        }
    },


    getUSDTBalance: async (privateKey) => {

        // Full node HTTP API endpoint
        const fullNode = 'https://nile.trongrid.io';

        // USDT contract address
        const usdtContractAddress = 'TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj';

        const tronWeb = new TronWeb({
            fullHost: fullNode,
            privateKey: privateKey
        });

        const address = tronWeb.defaultAddress.base58;

        // Get contract instance
        const contract = await tronWeb.contract().at(usdtContractAddress);

        // Call the balanceOf function
        const balance = await contract.methods.balanceOf(address).call();

        // Convert the balance from Sun to USDT (USDT has 6 decimals)
        const usdtBalance = tronWeb.toDecimal(balance) / 1e6;

        return usdtBalance;

    },

    // GetWalletInfo: async () => {

    //     //Шаг 1: Генерация мнемонической фразы
    //     const mnemonic = bip39.generateMnemonic();
    //     // console.log('Mnemonic:', mnemonic);

    //     // Шаг 2: Преобразование мнемонической фразы в сид
    //     const seed = bip39.mnemonicToSeedSync(mnemonic);

    //     // Шаг 3: Генерация мастер-ключа из сида
    //     const root = hdkey.fromMasterSeed(seed);

    //     // Шаг 4: Деривация ключей на основе пути (например, m/44'/195'/0'/0/0)
    //     const addrNode = root.derive("m/44'/195'/0'/0/0");

    //     // Шаг 5: Получение приватного ключа
    //     const privateKey = addrNode.privateKey.toString('hex');
    //     // console.log('Private Key:', privateKey);


    //     const publicKey = addrNode.publicKey.toString('hex');
    //     console.log('publicKey:', publicKey);


    //     // Шаг 6: Инициализация TronWeb с приватным ключом
    //     const tronWeb = new TronWeb({
    //         fullHost: 'https://nile.trongrid.io',
    //         privateKey: privateKey
    //     });


    //     // Get the address
    //     const address = tronWeb.address.fromPrivateKey(privateKey);


    //     // console.log('Address:', address);
    //     return {
    //         privateKey: privateKey,
    //         mnemonic: mnemonic,
    //         address: address,
    //         publicKey: publicKey
    //     };
    // },

    transferUSDT: async (body) => {
    
        console.log(body);
        
        // const tronWeb = new TronWeb({
        //   fullHost: 'https://nile.trongrid.io',
        //   privateKey: '5fd4a64dded8fd6f568d9e9537ebcdef2a5e5a377da7babb87b435d0ab66133c'
        // });    

        const tronWeb = new TronWeb({
        fullHost: 'https://nile.trongrid.io',
        privateKey: body.private_key
        });

        const usdtContractAddress = 'TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj';
        // const recipientAddress = 'TG5eTusTHum6rViWEDGw4K1MrYFFNcifnk';
        const recipientAddress = body.destination;
        // const amount = 1000000; // 1 USDT
        const amount = body.amount*1000000; // 1 USDT

        try 
        {
            const contract = await tronWeb.contract().at(usdtContractAddress);
            const result = await contract.methods.transfer(
                recipientAddress,
                amount
            ).send({
                feeLimit: 10000000
            });

            console.log('Transaction Result:', result);
            
            return result;
        } 
        catch (error) 
        {
            console.error('Error during transfer:', error);

            return false;
        }
    },

    sendTRX: async (body) => {
        
        try 
        {

            // const tronWeb = new TronWeb({
            //   fullHost: 'https://nile.trongrid.io',
            //   privateKey: '5fd4a64dded8fd6f568d9e9537ebcdef2a5e5a377da7babb87b435d0ab66133c'
            // });
            const tronWeb = new TronWeb({
            fullHost: 'https://nile.trongrid.io',
            privateKey: body.private_key
            });

            // const recipientAddress = 'TG5eTusTHum6rViWEDGw4K1MrYFFNcifnk';
            const recipientAddress = body.destination;
            const amount = body.amount;

            // Convert amount to SUN (the smallest unit of TRX)
            const amountInSun = tronWeb.toSun(amount);
            
            // Create a transaction object
            const transaction = await tronWeb.transactionBuilder.sendTrx(
            recipientAddress,
                amountInSun,
                tronWeb.defaultAddress.base58
            );

            // Sign the transaction
            const signedTransaction = await tronWeb.trx.sign(transaction);

            // Broadcast the transaction
            const receipt = await tronWeb.trx.sendRawTransaction(signedTransaction);

            console.log('Transaction receipt:', receipt);

            return receipt;
        } 
        catch (error) 
        {
            console.error(`Error sending TRX: ${error}`);
        }
    },



    get_wallet_info_route_handler: async (data) => {


        let request = {};
        // Display the key/value pairs
        for (const pair of data.body.entries()) 
        {
            // console.log();
            // console.log('get_wallet_info_route_handler',[pair[0], pair[1]]);
            request[pair[0]]=pair[1];

        }

        const privateKey = request['privateKey'];

        // console.log(privateKey);

        const trxPriceTronscan = await jsBackend.getTrxPriceTronscan();
      
        const USDTBalance = await jsBackend.getUSDTBalance(privateKey);

        const TRXbalance = await jsBackend.checkBalance(privateKey);

        const result = {
            'USDTBalance':USDTBalance,
            'TRXbalance':TRXbalance,
            'TRXpriceInUSDT':trxPriceTronscan
          };
        console.log('result:',result);
        
        return {"status":"success","result":{"wallet_info":result}};

    },

    get_wallets_for_user_route_handler: async () => {

        const wallets = await db.wallets
        .orderBy("name")
        .toArray();


        console.log(wallets);

        let wallets_result = [];

        for (const wallet of wallets) 
        {

            const trxPriceTronscan = await jsBackend.getTrxPriceTronscan();
          
            const TRXbalance = await jsBackend.checkBalance(wallet['private_key']);
            

            const total_sum = (TRXbalance*trxPriceTronscan).toFixed(3);

            const wallet_info= {
                'id':wallet['id'],
                'total_sum':total_sum,
                'wallet_name':wallet['name'],
                'address':wallet['address'],
                'privateKey':wallet['private_key'],
            };

            wallets_result.push(wallet_info);
        }


        console.log('wallets_result:',wallets_result);
        
        return {"status":"success","result":wallets_result};


    },

    generate_route_handler: async () => {

        // const createdWallet = await jsBackend.createTronWallet();

        const request = await fetch('https://dev.mageo.ru/api/create_wallet');

        var createdWallet = await request.json();

        console.log('wallets=1',createdWallet);


        const id = await db.wallets.add({
            name: 'createdWallet-' + getRandomInt(10000),
            address: createdWallet['address'],
            private_key: createdWallet['privateKey'],
            seed: createdWallet['mnemonic'],
        });

        console.log('wallets=2');


        return {
            "status":"success",
            "result":{ 
                'wallet':  createdWallet['address'],
                'privateKey' : createdWallet['privateKey'],
                'mnemonic' : createdWallet['mnemonic']
            }
        };
        

    },

    import_wallet_by_seed_route_handler: async (data) => {

        let request = {};
        for (const pair of data.body.entries()) 
        {
            request[pair[0]]=pair[1];
        }

        const seed = request['seed'];

        const createdWallet = await jsBackend.deriveTronAddressFromMnemonic(seed);
        
        // console.log('Transaction result:', createdWallet);


        const id = await db.wallets.add({
            name: 'importedWallet-' + getRandomInt(10000),
            address: createdWallet['address'],
            private_key: createdWallet['privateKey'],
            seed: seed,
        });

        // console.log('wallets=3');


        return {
            "status":"success",
            "result":true
        };
        

    },

    transfer_route_handler: async (data) => {

        let request = {};
        for (const pair of data.body.entries()) 
        {
            request[pair[0]]=pair[1];
        }

        const wallets = await db.wallets
            .where("address")
            .equals(request['departure'])
            .toArray();

        let wallet = wallets[0];
            
        console.log('wal===111',wallet);

        const params = {
            'departure' : request['departure'],
            'destination' : request['destination'],
            'amount' :  request['amount'],
            'token' : request['token'],
            'private_key' : wallet['private_key'],
        };

        console.log('params===111',params);

        let transaction = null;

        if(params['token'] === 'TRX')
        {
          transaction = await jsBackend.sendTRX(params);
        }
        if(params['token'] === 'USDT')
        {
          transaction = await jsBackend.transferUSDT(params);
        }
        
        // let transfer_result = {
        //   'success':true,
        //   'transaction':transaction
        // };

        const transactionResult = {
            'transactionId' : transaction['txid'],
            'transaction' : transaction,
            'departure'   : request['departure'],
            'destination' : request['destination'],
            'amount'      : request['amount'],
            'fee'         : 0.2,
            'timestamp'   : 'time()',
            'token'       :  request['token'],
        };
        
        return {"status":"success","result":{"transaction":transactionResult}};



    }
};



export default jsBackend;